import React, { useState, useEffect, useMemo } from 'react';
import {
  IconButton,
  Typography,
  Divider,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Switch,
  CircularProgress
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import {
  COLOR_CHOICES,
  DEFAULT_LEGAL_TEXT,
  SIZE_CHOICES,
  WEIGHT_CHOICES
} from '../constants';
import ImageKitFileUpload from '../../../components/ImageKitFileUpload';
import RemoveDialog from './RemoveDialog';
import { validateLink } from '../api';

const AdDrawer = ({
  onClose = () => {},
  onSave = () => {},
  data = {},
  uid,
  title = 'מודעה'
}) => {
  const notify = useNotify();
  const [desktopImage, setDesktopImage] = useState(
    data.desktopImageUrl || null
  );
  const [loading, setLoading] = useState(false);
  const [carsFound, setCarsFound] = useState(null);
  const [linkError, setLinkError] = useState(false);
  const [startDate, setStartDate] = useState(data.startDate || '');
  const [endDate, setEndDate] = useState(data.endDate || '');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [status, setStatus] = useState(data.status || null);
  const [campaignName, setCampaignName] = useState(data.campaignName || null);
  const [clickable, setClickable] = useState(data.clickable || null);
  const [legalStatus, setLegalStatus] = useState(data.legalStatus || false);
  const [legalColor, setLegalColor] = useState(data.legalColor || null);
  const [text, setText] = useState(data.text || null);
  const [textWeight, setTextWeight] = useState(data.textWeight || null);
  const [textSize, setTextSize] = useState(data.textSize || null);
  const [textColor, setTextColor] = useState(data.textColor || null);
  const [buttonText, setButtonText] = useState(data.buttonText || null);
  const [buttonTextWeight, setButtonTextWeight] = useState(
    data.buttonTextWeight || null
  );
  const [buttonTextSize, setButtonTextSize] = useState(
    data.buttonTextSize || null
  );
  const [buttonTextColor, setButtonTextColor] = useState(
    data.buttonTextColor || null
  );
  const [buttonBgColor, setButtonBgColor] = useState(
    data.buttonBgColor || null
  );
  const [url, setUrl] = useState(data.url || null);
  const [isStatusDisabled, setIsStatusDisabled] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [errors, setErrors] = useState({
    legalColor: data.legalColor === null
  });
  const modified = { ...data };
  const keys = [
    'campaignName',
    'desktopImageUrl',
    'startDate',
    'endDate',
    'location',
    'status',
    'text',
    'textColor',
    'textSize',
    'textWeight',
    'buttonText',
    'buttonTextColor',
    'buttonTextSize',
    'buttonTextWeight',
    'buttonBgColor',
    'url',
    'clickable',
    'legalText',
    'legalColor',
    'legalStatus'
  ];

  const useStyles = makeStyles({
    errorMessage: {
      color: 'red',
      fontSize: '0.75rem',
      marginLeft: '14px',
      marginRight: '14px',
      fontFamily: "'Noto Sans Hebrew', 'Noto Sans', sans-serif"
    }
  });

  const classes = useStyles();

  const validateSearchUrl = async () => {
    try {
      setCarsFound(-1);
      setLoading(true);

      const res = await validateLink({ url, branches: [] });

      setLoading(false);
      setCarsFound(res.count);
    } catch (err) {
      notify('קרתה שגיאה בבדיקת כמות הרכבים בסינון', 'error');
      setLoading(false);
      setCarsFound(-1);
    }
  };

  const areButtonPropsValid = useMemo(() => {
    return !!(
      buttonText &&
      buttonTextColor &&
      buttonBgColor &&
      buttonTextSize &&
      buttonTextWeight
    );
  }, [
    buttonText,
    buttonTextColor,
    buttonBgColor,
    buttonTextSize,
    buttonTextWeight
  ]);

  const areRequiredFieldsFilled = () => {
    return (
      !!startDate &&
      !!endDate &&
      !!campaignName &&
      !!desktopImage &&
      !linkError &&
      ((areButtonPropsValid && !!url) || !areButtonPropsValid) &&
      ((legalStatus && !!legalColor) || !legalStatus)
    );
  };

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];

    if (!startDate || !endDate || startDate > today || today > endDate) {
      setStatus('inactive');
      setIsStatusDisabled(true);
    } else {
      setIsStatusDisabled(false);
    }
  }, [startDate, endDate]);

  const handleSave = (clear = false) => {
    if (clear) {
      Object.keys(modified).forEach((key) => {
        modified[key] = null;
      });

      modified.creatingUid = uid;
    } else {
      modified.startDate = startDate;
      modified.endDate = endDate;
      modified.creatingUid = uid;
      modified.desktopImageUrl = desktopImage;
      modified.status = status;
      modified.campaignName = campaignName;
      modified.text = text;
      modified.textColor = textColor;
      modified.textSize = textSize;
      modified.textWeight = textWeight;
      modified.buttonText = buttonText;
      modified.buttonTextColor = buttonTextColor;
      modified.buttonTextSize = buttonTextSize;
      modified.buttonTextWeight = buttonTextWeight;
      modified.buttonBgColor = buttonBgColor;
      modified.url = url;
      modified.clickable = clickable;
      modified.legalColor = legalColor;
      modified.legalStatus = legalStatus;
    }

    modified.adNum = data.adNum;
    modified.location = data.location || 'USEDCARS';

    onSave(modified);
  };

  const handleStartDateChange = (val) => {
    setStartDate(val);
    setDirty(true);
  };

  const handleEndDateChange = (val) => {
    setEndDate(val);
    setDirty(true);
  };

  const hasSomeData = () => {
    // Check if any value in the data object, excluding some keys that always have a value
    return Object.entries(data).some(([key, value]) => {
      if (
        !keys.includes(key) ||
        key === 'id' ||
        key === 'nodeId' ||
        key === 'location'
      ) {
        return false;
      }

      return !!value; // Convert value to boolean and check if it's truthy
    });
  };

  const helpText = `ניתן להעלות קבצים בפורמטים: JPG, PNG, GIF. גודל קובץ מקסימלי 10mb`;

  return (
    <div style={{ width: 700, padding: 16 }}>
      {/* Close button */}
      <IconButton
        style={{ position: 'absolute', top: 8, left: 8 }}
        onClick={() => onClose(dirty)}
      >
        <CloseIcon />
      </IconButton>

      {/* Title */}
      <Typography
        variant="h6"
        style={{ marginTop: 4, marginBottom: 8, color: '#519FAB' }}
      >
        {`${title} ${data.adNum}`}
      </Typography>

      {/* Divider */}
      <Divider style={{ marginBottom: 16 }} />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}
      >
        {/* Switch input */}
        <Grid item style={{ flex: 1 }}>
          <Typography
            variant="body1"
            style={{ marginLeft: 16, fontWeight: 'bold' }}
          >
            סטטוס
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={data.status === 'active'}
                checked={status === 'active'}
                name="status"
                color="primary"
                disabled={isStatusDisabled}
                onChange={(ev) => {
                  setStatus(ev.currentTarget.checked ? 'active' : 'inactive');
                  setDirty(true);
                }}
              />
            }
            label="פעיל"
          />
        </Grid>
        {/* Status label */}
      </Grid>

      {/* Date fields */}
      <Grid container spacing={2} style={{ marginBottom: 16 }}>
        <Grid item xs={6}>
          <TextField
            required
            variant="outlined"
            label="תאריך התחלה"
            type="date"
            defaultValue={data.startDate || ''}
            onChange={(ev) => {
              handleStartDateChange(ev.currentTarget.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            InputProps={{
              inputProps: { min: new Date().toISOString().split('T')[0] }
            }}
            error={errors.startDate}
            helperText={errors.startDate && 'שדה זה חובה'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            variant="outlined"
            label="תאריך סיום"
            type="date"
            defaultValue={data.endDate || ''}
            onChange={(ev) => {
              handleEndDateChange(ev.currentTarget.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            InputProps={{
              inputProps: {
                min: startDate || new Date().toISOString().split('T')[0]
              }
            }}
            error={errors.endDate}
            helperText={errors.endDate && 'שדה זה חובה'}
          />
        </Grid>
      </Grid>

      {endDate && startDate && endDate < startDate && (
        <p style={{ color: 'red' }}>
          לא ניתן להגדיר תאריך סיום שקטן מתאריך ההתחלה
        </p>
      )}

      <div>
        <FormControlLabel
          style={{ marginBottom: '10px' }}
          control={
            <Checkbox
              defaultChecked={data.clickable || false}
              color="primary"
              checked={clickable && !!url}
              disabled={!url}
              onChange={(ev) => {
                setClickable(ev.currentTarget.checked);
                setDirty(true);
              }}
            />
          }
          label="קליאבלי"
        />
      </div>

      <div>
        <FormControlLabel
          style={{ marginBottom: '10px' }}
          control={
            <Switch
              defaultChecked={data.legalStatus || false}
              color="primary"
              onChange={(ev) => {
                setLegalStatus(ev.currentTarget.checked);
                setDirty(true);
              }}
            />
          }
          label="אפשר טקסט משפטי על הבאנר"
        />
      </div>

      {legalStatus && (
        <>
          <TextField
            label="טקסט משפטי"
            disabled
            multiline
            fullWidth
            defaultValue={DEFAULT_LEGAL_TEXT}
            variant="outlined"
            style={{ marginBottom: 16 }}
          />

          <FormControl
            fullWidth
            style={{ marginBottom: 16 }}
            error={errors.legalColor}
          >
            <InputLabel style={{ right: '10px' }}>
              {' '}
              צבע טקסט משפטי *{' '}
            </InputLabel>
            <Select
              required
              defaultValue={data.legalColor || ''}
              onBlur={(e) =>
                setErrors({ legalColor: legalStatus && !e.target.value })
              }
              onChange={(e) => {
                setLegalColor(e.target.value);
                setDirty(true);
              }}
              variant="outlined"
            >
              {COLOR_CHOICES.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {errors.legalColor && (
              <p className={classes.errorMessage}>שדה זה חובה</p>
            )}
          </FormControl>
        </>
      )}

      {/* Text fields */}
      <TextField
        label="שם קמפיין"
        onBlur={(e) => setErrors({ campaignName: !e.target.value })}
        defaultValue={data.campaignName || ''}
        onChange={(e) => {
          setCampaignName(e.target.value);
          setDirty(true);
        }}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
        error={errors.campaignName}
        helperText={errors.campaignName && 'שדה זה חובה'}
        required
        inputProps={{ maxLength: 30 }}
      />
      <TextField
        label="טקסט"
        onBlur={(e) => setErrors({ text: !e.target.value })}
        defaultValue={data.text || ''}
        onChange={(e) => {
          setText(e.target.value);
          setDirty(true);
        }}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
        // error={errors.text}
        // helperText={errors.text && 'שדה זה חובה'}
      />

      {/* Select input */}
      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>צבע טקסט </InputLabel>
        <Select
          defaultValue={data.textColor || ''}
          onBlur={(e) => setErrors({ textColor: !e.target.value })}
          // error={errors.textColor}
          // helperText={errors.textColor && 'שדה זה חובה'}
          onChange={(e) => {
            setTextColor(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {COLOR_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {/* {errors.textColor && (
          <p className={classes.errorMessage}>שדה זה חובה</p>
        )} */}
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>משקל טקסט </InputLabel>
        <Select
          // error={errors.textWeight}
          // helperText={errors.textWeight && 'שדה זה חובה'}
          onBlur={(e) => setErrors({ textWeight: !e.target.value })}
          defaultValue={data.textWeight || ''}
          onChange={(e) => {
            setTextWeight(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {WEIGHT_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {/* {errors.textWeight && (
          <p className={classes.errorMessage}>שדה זה חובה</p>
        )} */}
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>גודל טקסט </InputLabel>
        <Select
          onBlur={(e) => setErrors({ textSize: !e.target.value })}
          // error={errors.textSize}
          // helperText={errors.textSize && 'שדה זה חובה'}
          defaultValue={data.textSize || ''}
          onChange={(e) => {
            setTextSize(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {SIZE_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {/* {errors.textSize && <p className={classes.errorMessage}>שדה זה חובה</p>} */}
      </FormControl>

      <TextField
        label="טקסט כפתור"
        // error={errors.buttonText}
        // helperText={errors.buttonText && 'שדה זה חובה'}
        defaultValue={data.buttonText || ''}
        onChange={(e) => {
          setButtonText(e.target.value);
          setDirty(true);
        }}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
        inputProps={{ maxLength: 20 }}
      />

      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>צבע טקסט כפתור </InputLabel>
        <Select
          onBlur={(e) => setErrors({ buttonTextColor: !e.target.value })}
          // error={errors.buttonTextColor}
          // helperText={errors.buttonTextColor && 'שדה זה חובה'}
          defaultValue={data.buttonTextColor || ''}
          onChange={(e) => {
            setButtonTextColor(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {COLOR_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {/* {errors.buttonTextColor && (
          <p className={classes.errorMessage}>שדה זה חובה</p>
        )} */}
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>משקל טקסט כפתור </InputLabel>
        <Select
          // error={errors.buttonTextWeight}
          // helperText={errors.buttonTextWeight && 'שדה זה חובה'}
          onBlur={(e) => setErrors({ buttonTextWeight: !e.target.value })}
          defaultValue={data.buttonTextWeight || ''}
          onChange={(e) => {
            setButtonTextWeight(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {WEIGHT_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {/* {errors.buttonTextWeight && (
          <p className={classes.errorMessage}>שדה זה חובה</p>
        )} */}
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>גודל טקסט כפתור </InputLabel>
        <Select
          onBlur={(e) => setErrors({ buttonTextSize: !e.target.value })}
          // error={errors.buttonTextSize}
          // helperText={errors.buttonTextSize && 'שדה זה חובה'}
          defaultValue={data.buttonTextSize || ''}
          onChange={(e) => {
            setButtonTextSize(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {SIZE_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {/* {errors.buttonTextSize && (
          <p className={classes.errorMessage}>שדה זה חובה</p>
        )} */}
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>צבע רקע כפתור </InputLabel>
        <Select
          onBlur={(e) => setErrors({ buttonBgColor: !e.target.value })}
          // error={errors.buttonBgColor}
          // helperText={errors.buttonBgColor && 'שדה זה חובה'}
          defaultValue={data.buttonBgColor || ''}
          onChange={(e) => {
            setButtonBgColor(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {COLOR_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {errors.buttonBgColor && (
          <p className={classes.errorMessage}>שדה זה חובה</p>
        )}
      </FormControl>

      <TextField
        required={areButtonPropsValid}
        // error={errors.url}
        // helperText={errors.url && 'שדה זה חובה'}
        label="לינק להפנייה"
        defaultValue={data.url || ''}
        onChange={(e) => {
          setUrl(e.target.value);
          setDirty(true);
          setCarsFound(-1);
        }}
        onBlur={(e) => {
          const { value } = e.target;

          setErrors({ url: !value && areButtonPropsValid });
          setLinkError(
            // areButtonPropsValid &&
            value && !(value.startsWith('http') || value.includes('used-cars'))
          );
        }}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
        InputProps={{
          style: { direction: 'ltr' },
          startAdornment: <LinkIcon />
        }}
      />

      {linkError && (
        <p style={{ color: 'red' }}>
          ניתן להגדיר לינקים שמתחילים בhttp או בused-cars בלבד
        </p>
      )}

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <Button
          variant="contained"
          color="primary"
          helperText="* לא ניתן לשמור טרם ביצוע בדיקת רכבים"
          onClick={validateSearchUrl}
          disabled={loading || !url || !url.includes('used-cars')}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            'הצג כמות רכבים המתאימים לסינון'
          )}
        </Button>
        {carsFound !== null && carsFound >= 0 && (
          <Typography style={{ marginRight: 16 }}>
            {carsFound >= 10
              ? `נמצאו ${carsFound} רכבים `
              : `נמצאו ${carsFound} רכבים  , לא ניתן לסנן עבור מספר נמוך של רכבים`}
          </Typography>
        )}
      </div>
      {carsFound === -1 && areButtonPropsValid && (
        <Typography variant="caption" style={{ margin: 5 }}>
          * לא ניתן לשמור טרם ביצוע בדיקת רכבים
        </Typography>
      )}

      <ImageKitFileUpload
        maxSizeMB={10}
        helpText={helpText}
        onUploadSuccess={setDesktopImage}
        onImageChange={() => setDirty(true)}
        text="לחץ כאן כדי להעלות מדיה לדקסטופ"
        initialImage={desktopImage}
        folder="hero-content"
        required={errors.desktopImage}
        icon={<DesktopMacIcon fontSize="large" />}
      />

      <br />

      {/* Save and Cancel buttons */}
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Button
          disabled={!hasSomeData()}
          variant="outlined"
          color="secondary"
          onClick={() => setDialogOpen(true)}
          style={{
            marginLeft: 16,
            width: '40%',
            color: 'red',
            border: '1px solid red'
          }}
        >
          הסרה
        </Button>

        <Button
          disabled={
            !dirty ||
            !areRequiredFieldsFilled() ||
            (url &&
              url.includes('used-cars') &&
              carsFound !== null &&
              carsFound < 10)
          }
          variant="contained"
          color="primary"
          onClick={() => handleSave(false)}
          style={{ width: '40%' }}
        >
          שמירה
        </Button>
      </div>

      <RemoveDialog
        onConfirm={() => handleSave(true)}
        open={isDialogOpen}
        onCancel={() => setDialogOpen(false)}
      />
    </div>
  );
};

export default AdDrawer;
