import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import useEditMaxFundingPercent from './hooks/useFundingPercentageLimit';
import useMaxYearsFromPresentLimit from './hooks/useMaxYearsFromPresentLimit';
import FundingPercentageLimitForm from './components/FundingPercentageLimitForm';
import MaxYearsFromPresentLimitForm from './components/MaxYearsFromPresentLimitForm';
import useFundingRates from '../../../utils/hooks/useFundingRates';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'flex-start'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }
}));

const EditFundingGlobalSettings = ({ isTruck = false }) => {
  const classes = useStyles();
  const { fundingRates, loading: loadingFundingRates } =
    useFundingRates(isTruck);
  const [latestFundingRate, setLatestFundingRate] = useState(null);

  useEffect(() => {
    if (fundingRates && fundingRates.length > 0) {
      setLatestFundingRate(fundingRates[fundingRates.length - 1]);
    }
  }, [fundingRates]);

  const {
    loading: loadingMaxPercent,
    update: updateMaxPercent,
    value: maxFundingPercent
  } = useEditMaxFundingPercent(isTruck);

  const {
    loading: loadingMaxYears,
    update: updateMaxYears,
    value: maxYears
  } = useMaxYearsFromPresentLimit(isTruck);

  if (loadingFundingRates) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <FundingPercentageLimitForm
        loading={loadingMaxPercent}
        onUpdate={updateMaxPercent}
        value={maxFundingPercent}
      />

      <MaxYearsFromPresentLimitForm
        loading={loadingMaxYears}
        onUpdate={updateMaxYears}
        value={maxYears}
        latestFundingRate={latestFundingRate}
      />
    </div>
  );
};

export default EditFundingGlobalSettings;
