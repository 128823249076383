import React from 'react';
import { Create } from 'react-admin';
import FundingCalculatorMutation from './FundingCalculatorMutation';
import useFundingRates from '../../utils/hooks/useFundingRates';

const FundingCalculatorCreate = (props) => {
  const isTruck = props?.isTruck || false;
  const { fundingRates } = useFundingRates(isTruck);

  if (!fundingRates) return null;

  const lastFundingRate = fundingRates.length
    ? fundingRates[fundingRates.length - 1]
    : { maxYearsAfterPresent: -1 };
  const newMinYears = lastFundingRate.maxYearsAfterPresent + 1;
  const latestId = lastFundingRate.id + 1;

  return (
    <Create {...props}>
      <FundingCalculatorMutation
        {...props}
        latestId={latestId}
        initialMinYears={newMinYears}
        isTruck={isTruck}
        fundingRates={fundingRates}
      />
    </Create>
  );
};

export default FundingCalculatorCreate;
