import React from 'react';
import labels from '../../../labels';
import FundingCalculatorCreate from '../FundingCalculatorCreate';
import FundingCalculatorEdit from '../FundingCalculatorEdit';
import FundingCalculatorList from '../FundingCalculatorList';
// import FundingCalculatorUpdateList from './FundingCalculatorUpdateList';
// import { FundingCalcVariableUpdateEditList } from './FundingCalcVariableUpdateEditList';

export default {
  name: 'FundingRatesTruck',
  list: (props) => <FundingCalculatorList {...props} isTruck />,
  edit: (props) => <FundingCalculatorEdit {...props} isTruck />,
  create: (props) => <FundingCalculatorCreate {...props} isTruck />,

  options: {
    label: labels.fundingCalculatorTrucksUpdatePage,
    group: 'parameters'
  }
};
