/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Box,
  Typography,
  Link
} from '@material-ui/core';
import { useDataProvider, useTranslate, useRedirect } from 'react-admin';

const AddressDialog = ({ open, onClose, onSave }) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [cityOptions, setCityOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    address: '',
    city: '',
    latitude: '',
    longitude: '',
    district: ''
  });

  useEffect(() => {
    dataProvider
      .getList('CityLocation', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'city', order: 'ASC' }
      })
      .then(({ data }) => setCityOptions(data));

    dataProvider
      .getList('District', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'district', order: 'ASC' }
      })
      .then(({ data }) => setDistrictOptions(data));
  }, [dataProvider]);

  const validateCoordinates = (name, value) => {
    let error = '';

    if (!value) {
      error = 'שדה חובה';
    } else if (Number.isNaN(Number(value))) {
      error = translate('ra.validation.number');
    } else if (name === 'latitude' && (value < -90 || value > 90)) {
      error = 'ערך חייב להיות בין 90 ל 90-';
    } else if (name === 'longitude' && (value < -180 || value > 180)) {
      error = 'ערך חייב ליות בין 180 ל 180-';
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateAddress = (address) => {
    const regex = /\d/;
    let error = '';

    if (address && address.trim().length > 100) {
      error = 'יתן להזין עד 100 תווים';
    }

    if (!regex.test(address)) {
      error = 'יש להזין את הכתובת במלואה';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      address: error
    }));
  };

  const validateRequired = (name, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? '' : 'שדה חובה'
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    if (name === 'latitude' || name === 'longitude') {
      validateCoordinates(name, value);
    } else {
      validateRequired(name, value);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (name === 'address') {
      validateAddress(value);
    }
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === '') &&
      Object.values(formData).every((value) => value !== '')
    );
  };

  const handleSave = () => {
    if (isFormValid()) {
      const city = cityOptions.find((c) => c.nodeId === formData.city);
      const district = districtOptions.find(
        (d) => d.nodeId === formData.district
      );

      formData.city = city.city;
      formData.district = district.district;
      formData.street = formData.address;
      formData.address = `${formData.address},${formData.city}`;

      onSave(formData);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>הוספת כתובת חדשה</DialogTitle>
      <DialogContentText style={{ marginRight: 20 }}>
        כתובת הסניף לא קיימת ברשימת הכתובות. האם להוסיף אותה לרשימה?
      </DialogContentText>
      <DialogContent>
        <TextField
          label="רחוב *"
          name="address"
          fullWidth
          margin="dense"
          onChange={handleChange}
          onBlur={handleBlur} // Add blur event handler here
          error={!!errors.address}
          helperText={errors.address || ''}
        />
        <TextField
          select
          label="עיר *"
          name="city"
          fullWidth
          margin="dense"
          onChange={handleChange}
          error={!!errors.city}
          helperText={errors.city || ''}
        >
          {cityOptions.map((city) => (
            <MenuItem key={city.id} value={city.id}>
              {city.city}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Latitude *"
          name="latitude"
          fullWidth
          margin="dense"
          onChange={handleChange}
          error={!!errors.latitude}
          helperText={errors.latitude || ''}
        />
        <TextField
          label="Longitude *"
          name="longitude"
          fullWidth
          margin="dense"
          onChange={handleChange}
          error={!!errors.longitude}
          helperText={errors.longitude || ''}
        />
        <TextField
          select
          label="מחוז * "
          name="district"
          fullWidth
          margin="dense"
          onChange={handleChange}
          error={!!errors.district}
          helperText={errors.district || ''}
        >
          {districtOptions.map((district) => (
            <MenuItem key={district.id} value={district.id}>
              {district.district}
            </MenuItem>
          ))}
        </TextField>
        <Box display="flex" alignItems="center" marginTop={2}>
          <Typography variant="subtitle1">
            במידה והעיר לא קיימת ברשימת הערים, להוספת העיר
          </Typography>
          <Link
            onClick={() => redirect('/CityLocation/create')}
            style={{
              marginRight: 5,
              cursor: 'pointer',
              textDecoration: 'underline',
              color: 'blue'
            }}
          >
            לחץ כאן
          </Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={!isFormValid()}
        >
          הוספת כתובת חדשה
        </Button>
        <Button variant="text" onClick={onClose} color="primary">
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddressDialog;
