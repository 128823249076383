import labels from '../../labels';
import TruckParametersManage from './TruckParametersManage';

export default {
  name: 'TruckParameters',
  list: TruckParametersManage,

  options: {
    label: labels.truckParameters,
    group: 'parameters'
  }
};
