import { filter, map, pipe, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const useAgenciesForAutoComplete = () => {
  const [agencies, setAgencies] = useState();
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchAgencies = async () => {
    const { data } = await dataProvider(GET_LIST, 'Agency', {
      filter: {
        specificFields: [
          'nodeId',
          'id',
          'name',
          'displayName',
          'psid',
          'sfid',
          'rating'
        ]
      },
      pagination: { page: 1, perPage: 2500 }
    });

    const mappedAgencies = pipe(
      map((agency) => ({
        id: prop('originalId', agency),
        name: `${prop('displayName', agency)} ${prop('name', agency)}`,
        sfid: prop('sfid', agency),
        psid: prop('psid', agency),
        rating: prop('rating', agency)
      })),
      filter(prop('id'))
    )(data);

    setAgencies(mappedAgencies);
  };

  useEffect(() => {
    fetchAgencies();
  }, [version]);

  return {
    agencies
  };
};

export default useAgenciesForAutoComplete;
