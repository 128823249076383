export const COLOR_CHOICES = [
  { id: '#28CBFF', name: 'כחול' },
  { id: '#15142B', name: 'כחול כהה' },
  { id: '#FCF8C8', name: 'צהוב' },
  { id: '#E0F1FB', name: 'תכלת' },
  { id: '#F2F6F7', name: 'אפור' },
  { id: '#FFFFFF', name: 'לבן' }
];

export const WEIGHT_CHOICES = [
  { id: 'normal', name: 'NORMAL' },
  { id: 'bold', name: 'BOLD' },
  { id: 'thin', name: 'THIN' }
];

export const SIZE_CHOICES = [
  { id: 'h1', name: 'H1' },
  { id: 'h2', name: 'H2' },
  { id: 'h3', name: 'H3' },
  { id: 'normal', name: 'NORMAL' }
];

export const LOCATIONS = {
  home: 'דף הבית',
  usedcars: 'דף חיפוש',
  home_usedcars: 'דף הבית, דף חיפוש'
};

export const DEFAULT_LEGAL_TEXT =
  'מספר רישיון 54414. אי עמידה בפירעון ההלוואה או בהחזר האשראי עלולה לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל.';
