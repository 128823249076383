import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import useSiteSettings from '../../utils/hooks/useSiteSettings';

const TruckSettings = () => {
  const {
    isLoading,
    numOfSeats,
    numOfTires,
    numSideDoors,
    showTruckInUI,
    setNumOfSeats,
    setNumOfTires,
    setNumSideDoors,
    setShowTruckInUI,
    handleSave
  } = useTruckSettings();

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            gap: 3
          }}
        >
          <Box
            sx={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
              gap: 20,
              flexWrap: 'wrap',
              flexGrow: 1
            }}
          >
            <Box sx={{ minWidth: '300px' }}>
              <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                מספר נוסעים
              </Typography>
              <Box sx={{ paddingLeft: 2 }}>
                <MinMaxInput value={numOfSeats} onChange={setNumOfSeats} />
              </Box>
            </Box>

            <Box sx={{ minWidth: '300px' }}>
              <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                מספר צמיגים
              </Typography>
              <Box sx={{ paddingLeft: 2 }}>
                <MinMaxInput value={numOfTires} onChange={setNumOfTires} />
              </Box>
            </Box>

            <Box sx={{ minWidth: '300px' }}>
              <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                מספר דלתות צד
              </Typography>
              <Box sx={{ paddingLeft: 2 }}>
                <MinMaxInput value={numSideDoors} onChange={setNumSideDoors} />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: 2
            }}
          >
            <Button variant="contained" color="primary" onClick={handleSave}>
              שמור
            </Button>
          </Box>
        </Box>

        <FormControlLabel
          control={
            <Switch
              defaultChecked={showTruckInUI}
              onChange={(e) => setShowTruckInUI(e.target.checked)}
            />
          }
          style={{ marginTop: '20px' }}
          label="הצג משאיות בלוח מודעות"
        />
      </CardContent>
    </Card>
  );
};

const MinMaxInput = ({ value, onChange }) => {
  const [min, max] = value;

  const handleMinChange = (newMin) => {
    onChange([Number(newMin), max]);
  };

  const handleMaxChange = (newMax) => {
    onChange([min, Number(newMax)]);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <Typography variant="body2" sx={{ minWidth: '25px' }}>
          מ-
        </Typography>
        <TextField
          type="number"
          value={min}
          onChange={(e) => handleMinChange(e.target.value)}
          size="small"
          sx={{ width: '60px' }}
          inputProps={{
            min: 0,
            max: 99,
            maxLength: 2,
            style: { textAlign: 'center' }
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4.5 }}>
        <Typography variant="body2" sx={{ minWidth: '25px' }}>
          עד
        </Typography>
        <TextField
          type="number"
          value={max}
          onChange={(e) => handleMaxChange(e.target.value)}
          size="small"
          sx={{ width: '60px' }}
          inputProps={{
            min: 0,
            max: 99,
            maxLength: 2,
            style: { textAlign: 'center' }
          }}
        />
      </Box>
    </Box>
  );
};

const TRUCK_SETTINGS_KEY = 'truck_settings';

const useTruckSettings = () => {
  const notify = useNotify();
  const siteSettings = useSiteSettings(TRUCK_SETTINGS_KEY);
  const [isLoading, setIsLoading] = useState(false);
  const [numOfSeats, setNumOfSeats] = useState([2, 8]);
  const [numOfTires, setNumOfTires] = useState([4, 12]);
  const [numSideDoors, setNumSideDoors] = useState([2, 4]);
  const [showTruckInUI, setShowTruckInUI] = useState(false);

  useEffect(() => {
    const load = async () => {
      try {
        setIsLoading(true);
        const json = await siteSettings.getValue();
        const settings = parseTruckSettings(json);
        setNumOfSeats(settings.numOfSeats);
        setNumOfTires(settings.numOfTires);
        setNumSideDoors(settings.numSideDoors);
        setShowTruckInUI(settings.showTruckInUI);
      } catch (error) {
        notify('שגיאה בטעינת הגדרות משאית', { type: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    load();
  }, []);

  const handleSave = async () => {
    try {
      await siteSettings.saveValue(
        JSON.stringify({ numOfSeats, numOfTires, numSideDoors, showTruckInUI })
      );
      notify('הגדרות משאית נשמרו בהצלחה', { type: 'success' });
    } catch (error) {
      notify('שגיאה בשמירת הגדרות משאית', { type: 'error' });
    }
  };

  return {
    isLoading,
    numOfSeats,
    numOfTires,
    numSideDoors,
    showTruckInUI,
    setNumOfSeats,
    setNumOfTires,
    setNumSideDoors,
    setShowTruckInUI,
    handleSave
  };
};

function parseTruckSettings(json) {
  const settings = parseJson(json, {
    numOfSeats: [0, 10],
    numOfTires: [4, 12],
    numSideDoors: [4, 8],
    showTruckInUI: true // Default value
  });
  return {
    numOfSeats: settings.numOfSeats,
    numOfTires: settings.numOfTires,
    numSideDoors: settings.numSideDoors,
    showTruckInUI: Boolean(settings.showTruckInUI)
  };
}

function parseJson(json, defaultValue) {
  try {
    if (json === null) {
      return defaultValue;
    }
    const value = JSON.parse(json);
    return { ...defaultValue, ...value };
  } catch (e) {
    return defaultValue;
  }
}

export default TruckSettings;
