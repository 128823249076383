import { includes, isEmpty, isNil } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormState } from 'react-final-form';
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  EditButton,
  maxValue,
  minValue,
  number,
  NumberInput,
  ReferenceManyField,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  SaveButton,
  Toolbar,
  usePermissions
} from 'react-admin';
import Typography from '@material-ui/core/Typography';

import useAgenciesForAutoComplete from '../../utils/hooks/useAgenciesForAutoComplete';
import useCitiesForAutoComplete from '../../utils/hooks/useCitiesForAutoComplete';
import useUsersForAutoComplete from '../../utils/hooks/useUsersForAutoComplete';
// import AgencyVideoInput from './AgencyVideoInput';
import AutocompletePlace from './AutocompletePlace';
import CityAdding from './CityAdding';
import OpenHours from './OpenHours';
import RepresentativeInput from './RepresentativeInput';
import StarwizInput from './StarwizInput';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { fetchDistricts } from '../Districts/api';

const validateScore = [
  number(),
  minValue(-20, 'הערך בשדה זה צריך להיות בין 20- ל30'),
  maxValue(30, 'הערך בשדה זה צריך להיות בין 20- ל30'),
  required()
];

const CustomToolbar = (props) => {
  const formState = useFormState();

  return (
    <Toolbar>
      <SaveButton
        disabled={!formState.valid || props.addressError}
        {...props}
      />
    </Toolbar>
  );
};

const AgencyBranchMutation = ({
  showRelatedUsers,
  initialValues,
  ...props
}) => {
  // const formState = useFormState();
  const { permissions, loaded: permissionsLoaded } = usePermissions();
  const { agencies } = useAgenciesForAutoComplete();
  const { users } = useUsersForAutoComplete([
    'ADMIN',
    'CARWIZ_AGENT',
    'BUSINESS_MANAGER',
    'BUSINESS_LOCAL'
  ]);
  const [addressError, setAddressError] = useState(false);
  const [version, setVersion] = useState(0);
  const { cities } = useCitiesForAutoComplete(version);
  const [cityAddingDialogOpen, setCityAddingDialogOpen] = useState(false);
  const [fundingAdvisor, setFundingAdvisor] = useState(
    initialValues?.fundingAdvisor
  );
  const [implementer, setImplementer] = useState(initialValues?.implementer);
  const [representative, setRepresentative] = useState(
    initialValues?.representative
  );
  const [selectedFundingAdvisor, setSelectedFundingAdvisor] = useState(
    initialValues?.fundingAdvisorUid
  );
  const [selectedImplementer, setSelectedImplementer] = useState(
    initialValues?.implementerUid
  );
  const [selectedRepresentative, setSelectedRepresentative] = useState(
    initialValues?.representativeUid
  );
  const [selectedAreaManager, setSelectedAreaManagerUser] = useState(
    initialValues?.areaManagerUid
  );
  const [areaManager, setAreaManager] = useState(initialValues?.areaManager);
  const [moked, setMoked] = useState();
  const { token } = useFirebaseToken();
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    fetchDistricts(token).then(setDistricts);
  }, [token]);

  const handleExposedChange = (value) => {
    const booleanInputs = [
      'showInFbCatalog',
      'displayCustomerVirtualPhone',
      'allowStatistics',
      'recommend',
      'lngArabic',
      'lngEnglish',
      'lngRussian',
      'isCarlandBranch',
      'isMoked'
    ];

    if (!value) {
      // if exposed is falsy, turn off all other boolean inputs
      booleanInputs.forEach((input) => {
        const element = document.querySelector(`input[name="${input}"]`);
        if (element && element.checked) element.click();
      });
    }
  };

  const mimunAgents = useMemo(() => {
    const agents = [];
    const allowedRoles = [
      'ADMIN',
      'CARWIZ_AGENT',
      'BUSINESS_MANAGER',
      'BUSINESS_LOCAL'
    ];

    (users || []).forEach((u) => {
      if (u.role && allowedRoles.includes(u.role)) {
        agents.push(u);
      }
    });

    return agents;
  }, [users]);

  useEffect(() => {
    const selectedFundingAdvisorObj = (users || []).find(
      (u) => u.id === selectedFundingAdvisor
    );
    const selectedImplementerObj = (users || []).find(
      (u) => u.id === selectedImplementer
    );
    const selectedRepresentativeObj = (users || []).find(
      (u) => u.id === selectedRepresentative
    );
    const selecteAreanManagerObj = (users || []).find(
      (u) => u.id === selectedAreaManager
    );

    setAreaManager(selecteAreanManagerObj?.name || '');
    setFundingAdvisor(selectedFundingAdvisorObj?.name || '');
    setImplementer(selectedImplementerObj?.name || '');
    setRepresentative(selectedRepresentativeObj?.name || '');
  }, [
    selectedFundingAdvisor,
    selectedImplementer,
    selectedRepresentative,
    selectedAreaManager,
    users
  ]);

  const handleSubmit = (data, redirect) => {
    const now = new Date().toISOString();
    let fundingAdvisorToPatch = fundingAdvisor || null;
    let implementerToPatch = implementer || null;
    let representativeToPatch = representative || null;
    let areaManagerToPatch = areaManager || null;
    let res;
    const addressRegex =
      /^([\w\u0590-\u05FF\s.,'-]+),\s*([\w\u0590-\u05FF\s'-]+)$/;

    setAddressError(false);

    if (
      !data.address ||
      !data.address.trim().length ||
      !addressRegex.test(data.address)
    ) {
      setAddressError(true);
      return;
    }

    if (!fundingAdvisor && data.fundingAdvisorUid) {
      // incase the field is not updated but has a value
      res = (mimunAgents || []).find((u) => u.id === data.fundingAdvisorUid);

      fundingAdvisorToPatch = res?.name || null;
    }

    if (!representative && data.representativeUid) {
      res = (users || []).find((u) => u.id === data.representativeUid);

      representativeToPatch = res?.name || null;
    }

    if (!implementer && data.implementerUid) {
      res = (users || []).find((u) => u.id === data.implementerUid);

      implementerToPatch = res?.name || null;
    }

    if (!areaManager && data.areaManagerUid) {
      res = (users || []).find((u) => u.id === data.areaManagerUid);

      areaManagerToPatch = res?.name || null;
    }

    data.areaManager = areaManagerToPatch; // update area manager manually
    data.fundingAdvisor = fundingAdvisorToPatch; // update funding advisor manually
    data.implementer = implementerToPatch; // update implementer manually
    data.representative = representativeToPatch; // update representative manually
    data.updatedAt = now;

    // Check if manualScoreBooster has changed
    if (
      !data.id ||
      props.record.manualScoreBooster !== data.manualScoreBooster
    ) {
      res = (users || []).find((u) => u.id === permissions.uid);

      data.updatedManualFactorUser = res?.name || null;
      data.updatedManualFactorAt = now; // Update the updatedAt field to current date and time
    }

    if (!data.id) {
      data.createdAt = now;
    }

    props.save(data, redirect); // save the object
  };

  const placeAutoCompleteSelectionHandler = (
    city,
    district,
    cityChange,
    changeDistrict
  ) => {
    const value = cities.find((citiesCity) => {
      if (includes(city, citiesCity.name)) {
        cityChange(citiesCity.name);

        if (
          includes(
            citiesCity.district,
            districts.map((d) => d.name)
          )
        ) {
          changeDistrict(citiesCity.district);
        }

        return true;
      }

      return false;
    });

    if (!value) {
      setCityAddingDialogOpen(true);
    }
  };

  const handleCityAddingCancel = () => {
    setCityAddingDialogOpen(false);
  };

  const parseBoostWhenEmpty = (v) => {
    return isNil(v) || isEmpty(v) ? 0 : parseInt(v, 10);
  };

  const phoneValidator = (value) => {
    if (!value) return undefined;

    const phoneReg = /^05\d{8}$/;

    if (!value.match(phoneReg) && value.length < 5) {
      return 'phone number should start with 05';
    }
    if (value.length > 5 && value.length < 10) {
      return 'phone number is too short';
    }
    if (value.length > 10) {
      return 'phone number is too long';
    }

    return undefined;
  };

  const validateValue = (value) => {
    const isMokedValue = document.querySelector('#isMoked').checked || false;
    if (isMokedValue) return undefined;

    return value ? undefined : 'required';
  };

  const validatePhone = [phoneValidator];

  const onMokedUpdate = (value) => {
    setMoked(value);
  };

  return (
    <SimpleForm
      {...props}
      save={handleSubmit}
      initialValues={initialValues}
      toolbar={<CustomToolbar addressError={!!addressError} />}
    >
      {props.disableId ? (
        <NumberInput disabled label="מזהה" source="originalId" />
      ) : (
        <NumberInput label="מזהה" source="id" validate={required()} />
      )}
      <AutocompleteInput
        choices={agencies}
        source="agencyId"
        label="סוכנות"
        validate={required()}
      />
      <TextInput
        label={`שם סניף ${moked ? '' : ' *'}`}
        source="areaName"
        validate={validateValue}
      />
      <TextInput
        source="virtualPhone"
        label="מספר וירטואלי"
        validate={validatePhone}
      />

      <AutocompletePlace
        onAfterSelect={placeAutoCompleteSelectionHandler}
        showAddressError={(value) => setAddressError(value)}
        isMoked={moked}
      />

      {addressError && (
        <Typography
          style={{ marginTop: '5px', color: 'red', fontSize: '12px' }}
        >
          הכתובת אינה תקינה
        </Typography>
      )}

      <div style={{ display: 'none' }}>
        <TextInput disabled label="Longitude" source="longitude" />
        <TextInput disabled label="Latitude" source="latitude" />
        {/* <TextInput disabled label="City" source="city" /> */}
        <TextInput disabled label="Street" source="street" />
      </div>

      <NumberInput
        source="manualScoreBooster"
        label="פקטור לדירוג מודעה"
        step={1}
        min={-20}
        max={30}
        defaultValue={0}
        // inputProps={{
        //   style: {
        //     textAlign: 'left', // Ensure text aligns to the left
        //     direction: 'ltr' // Enforce left-to-right text direction
        //   }
        // }}
        // parse={parseBoostWhenEmpty}
        validate={validateScore}
        FormHelperTextProps={{ style: { fontSize: '10px' } }}
        helperText={
          props.record?.updatedManualFactorUser
            ? `עודכן ע״י ${
                props.record?.updatedManualFactorUser || ''
              }  בתאריך ${
                props.record?.updatedManualFactorAt
                  ? new Date(props.record.updatedManualFactorAt).toLocaleString(
                      'he-IL'
                    )
                  : ''
              }`
            : ''
        }
      />

      <SelectInput
        label="מחוז"
        source="district"
        choices={districts}
        disabled
      />

      <SelectInput
        label={`עיר ${moked ? '' : ' *'}`}
        source="city"
        choices={cities}
        validate={validateValue}
      />
      <CityAdding
        label="הוספת עיר חדשה"
        open={cityAddingDialogOpen}
        handleClose={() => {
          setCityAddingDialogOpen(false);
        }}
        handleCancel={handleCityAddingCancel}
        onAfterSubmit={() => setVersion((v) => v + 1)}
      />
      <TextInput
        disabled
        style={{ display: 'none' }}
        validate={validateValue}
        label={`כתובת ${moked ? '' : ' *'}`}
        source="address"
      />

      {/* <AgencyVideoInput /> */}
      {/* <TextInput label="Video" source="videoUrl" />
      <div style={{ display: 'none' }}>
        <TextInput label="Compressed Video" source="compressedVideoUrl" />
      </div> */}

      <BooleanInput label="הצגה ברשתות חברתיות" source="showInFbCatalog" />
      <BooleanInput
        label="הצגת מספרים וירטואלים"
        source="displayCustomerVirtualPhone"
      />
      <BooleanInput
        label="הצגת סטטיסטיקות עבור רכבים"
        source="allowStatistics"
      />
      <BooleanInput label="הצגה באינדקס סוכנויות" source="indexPage" />
      {/* <BooleanInput label="רכבים ללא מספר רישוי" source="allowNoPlate" /> */}
      <BooleanInput
        label="סניף פעיל"
        source="expose"
        onChange={handleExposedChange}
      />
      <BooleanInput label="המלצה" source="recommend" />
      {/* <BooleanInput label="סניף מוקד" source="isMoked" id="isMoked" /> */}
      <BooleanInput label="מדברים ערבית" source="lngArabic" />
      <BooleanInput label="מדברים אנגלית" source="lngEnglish" />
      <BooleanInput label="מדברים רוסית" source="lngRussian" />
      <BooleanInput label="סניף קארלנד" source="isCarlandBranch" />
      <OpenHours onMokedUpdate={onMokedUpdate} />
      <StarwizInput />
      {/* <TextInput multiline label="אודות הסוכנות" source="about" /> */}
      <NumberInput label="פוטנציאל מלאי" source="potentailStockAmount" />

      {/* <SelectInput
        source="areaManagerUid"
        label="Area Manager"
        choices={(users || []).filter((u) =>
          ['ADMIN', 'CARWIZ_AGENT'].includes(u.role)
        )}
        onChange={(event) => setSelectedAreaManagerUser(event.target.value)}
        value={selectedAreaManager}
      /> */}

      <AutocompleteInput
        allowEmpty
        onChange={(value) => setSelectedAreaManagerUser(value)}
        value={selectedAreaManager}
        choices={(users || []).filter((u) =>
          ['ADMIN', 'CARWIZ_AGENT'].includes(u.role)
        )}
        source="areaManagerUid"
        label="מנהל אזור"
      />

      <RepresentativeInput
        record={props.record}
        onRepresentiveChange={setSelectedRepresentative}
      />

      <AutocompleteInput
        allowEmpty
        onChange={(value) => setSelectedFundingAdvisor(value)}
        value={selectedFundingAdvisor}
        choices={mimunAgents}
        source="fundingAdvisorUid"
        label="יועץ מימון"
      />

      <AutocompleteInput
        allowEmpty
        onChange={(value) => setSelectedImplementer(value)}
        value={selectedImplementer}
        choices={users}
        source="implementerUid"
        label="מטמיע"
      />

      {showRelatedUsers && (
        <ReferenceManyField
          reference="User"
          target="agencyBranchId"
          source="originalId"
          label="users"
          filter={{
            specificFields: [
              'nodeId',
              'id',
              'name',
              'phone',
              'role',
              'agencyBranchId'
            ]
          }}
        >
          <Datagrid>
            <TextField source="name" />
            <TextField source="phone" />
            <TextField source="role" />

            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      )}
    </SimpleForm>
  );
};

export default AgencyBranchMutation;
