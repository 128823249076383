import React from 'react';
import { Box, Divider } from '@material-ui/core';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import TruckAccessoryList from './TruckAccessoryList';
import TruckTransportTypeList from './TruckTransportTypeList';
import TruckCabinTypeList from './TruckCabinTypeList';
import TruckSettings from './TruckSettings';

const TruckParametersManage = () => {
  const { token } = useFirebaseToken();

  return (
    <Box sx={{ paddingBottom: 100 }}>
      <TruckAccessoryList token={token} />
      <Divider variant="middle" />
      <TruckTransportTypeList token={token} />
      <Divider variant="middle" />
      <TruckCabinTypeList token={token} />
      <Divider variant="middle" />
      <TruckSettings token={token} />
    </Box>
  );
};

export default TruckParametersManage;
