export default {
  users: 'משתמשים',
  agencies: 'סוכנויות',
  agencyBranches: 'סניפים',
  images: 'תמונות',
  posts: 'מודעות',
  benefits: 'הטבות',
  agencyBenefits: 'הטבות לסוכנות',
  notificationRules: 'ניהול התראות למשתמש',
  hiddenPosts: 'הסתרת מודעות',
  notificationCategories: 'קטגוריית התראות',
  notificationTypes: 'סוגי התראות',
  carNames: 'ניהול שמות רכב',
  agents: 'סוכנים',
  filterableInsight: 'תובנות לסינון',
  shortLinks: 'קישורים מקוצרים',
  userProps: 'הרחבת משתמש',
  experiments: 'ניסויים',
  teams: 'צוותים',
  versionPageMapping: 'מיפוי דף גרסא',
  featuredModels: 'דגמים לדף הבית',
  subscriber: 'מנויים',
  interactionTypes: 'סוגי אינטראקציות',
  interactionTypeGroups: ' סלי אינטרקציות ',
  interactionTypesSecondaryStatus: 'תתי אינטראקציות',
  fundingCalculatorUpdatePage: 'פרמטרי מימון - רכב',
  cities: 'ערים',
  districts: 'מחוזות',
  uploadAttempts: 'ניסיונות העלאת רכב',
  mokedWorkingHour_contact: 'ניתוב שיחות למוקד CARWIZ',
  mokedWorkingHour_chat: "שעות פעילות צ'ט אונליין",
  campaigns: 'קמפיינים',
  uiCache: 'ניהול דפים סטאטים',
  carNameManagement: 'ניהול שמות רכב',
  loginTestUsers: 'משתמשי בדיקות',
  catalogJato: 'תמונות jato בקטלוג',
  generalParameters: 'פרמטרים כלליים',
  truckParameters: 'פרמטרים למשאיות',
  fundingCalculatorTrucksUpdatePage: 'פרמטרי מימון - משאיות'
};
